<template>
  <div class="container my-5">
    <div class="row justify-content-center">
      <div v-for="clock in clocks" :key="clock.zone" class="col-6 col-md-3 mb-4">
        <div class="clock p-3 shadow-sm rounded">
          <h3 class="clock-city">{{ clock.city }}</h3>
          <p class="clock-time">{{ formattedTime(clock.zone) }}</p>
          <p class="clock-date">{{ formattedDate(clock.zone) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "TimeZoneClock",
  data() {
    return {
      clocks: [
        {city: "Ciudad de México", zone: "America/Mexico_City"},
        {city: "Madrid", zone: "Europe/Madrid"},
        {city: "Buenos Aires", zone: "America/Argentina/Buenos_Aires"},
        {city: "Washington", zone: "America/New_York"},
      ],
    };
  },
  methods: {
    formattedTime(zone) {
      return moment().tz(zone).format("hh:mm A");
    },
    formattedDate(zone) {
      return moment().tz(zone).format("ddd, DD YYYY");
    },
  },
  mounted() {
    this.updateTime = setInterval(() => {
      this.$forceUpdate();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.updateTime);
  },
};
</script>

<style scoped>
.clock {
  text-align: center;
  background: #f8f9fa;
  min-height: 100px;
}

/* Control del tamaño de fuente */
.clock-city {
  font-size: 0.8em;
}

.clock-time {
  font-size: 1.5em;
}

.clock-date {
  font-size: 0.9em;
}

/* Media queries para ajustar tamaños en móviles */
@media (max-width: 768px) {
  .clock-city {
    font-size: 0.7em;
  }

  .clock-time {
    font-size: 1.3em;
  }

  .clock-date {
    font-size: 0.8em;
  }
}

@media (min-width: 769px) AND (max-width: 930px) {
  .clock-city {
    font-size: 0.3em;
  }

  .clock-time {
    font-size: 0.7em;
  }

  .clock-date {
    font-size: 0.6em;
  }
}
</style>
