<template>
  <div class="container-home d-flex justify-content-center align-items-center vh-100">
    <div class="container-image text-center">
      <h1 title="Softorres Creating your ideas" class="title-header">SOFTORRES</h1>
      <p title="Softorres Creating your ideas" class="subtitle-header"><span class="text-spaced">C R E A T I N G</span><span class="text-spaced">Y O U R</span><span class="text-spaced">I D E A S</span></p>
      <div class="subtitle-header text-contact-info mt-5">
        <hr>
        <p class="p-0 m-0">SALES, CONTACT OR MORE INFORMATION</p>
        <a href="mailto:contacto@softorres.com">contacto@softorres.com</a>

        <TimeZoneClock></TimeZoneClock>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
import TimeZoneClock from "@/components/TimeZoneClock.vue";

export default {
  name: "IndexHome",
  components: {
    TimeZoneClock
  },
};
</script>

<style scoped>
/* Media queries para ajustar estilos en pantallas más pequeñas */

.title-header,
.subtitle-header,
.text-contact-info {
  color: #2D2D2D !important;;
}



@media (max-width: 768px) {
  body .text-contact-info {
    font-size: 1.3em !important;
  }

  .title-header {
    font-size: calc(5em - 5vw) !important;
  }

  .subtitle-header {
    font-size: calc(0.8em - 1vw) !important;
  }

  .text-spaced {

  }
}

@media (min-width: 900px) AND (max-width: 1024px) {
  body .text-contact-info {
    font-size: 2.3em !important;
  }

  .title-header {
    font-size: calc(11em - 5vw) !important;
  }

  .subtitle-header {
    font-size: calc(4em - 5vw) !important;
  }

  .text-spaced {

  }
  .container-home {
    margin-top: 10em;
  }
}

.text-contact-info a,
.text-contact-info p {
  font-family: 'Hum512b', serif;
  font-size: .6em;
}

.title-header {
  font-family: 'Hum512b', serif;
  font-size: 10em;
}

.subtitle-header {
  font-family: 'LFAX', sans-serif;
  font-size: 1.5em;
}

.text-spaced:first-child {
  padding: 0;
}

.text-spaced {
  letter-spacing: 0.5em;
  display: inline-block;
  padding-left: 3em;
}

/* Archivo: src/assets/styles/fonts.css */

/* Fuente #1 */
@font-face {
  font-family: 'Hum512b';
  src: url('@/assets/fonts/Hum521b.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Hum521bi';
  src: url('@/assets/fonts/Hum521bi.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Hum521i';
  src: url('@/assets/fonts/Hum521i.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

/* Fuente #2 */
@font-face {
  font-family: 'LFAX';
  src: url('@/assets/fonts/LFAX.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LFAXD';
  src: url('@/assets/fonts/LFAXD.TTF') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'LFAXDI';
  src: url('@/assets/fonts/LFAXDI.TTF') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'LFAXI';
  src: url('@/assets/fonts/LFAXI.TTF') format('truetype');
  font-weight: normal;
  font-style: italic;
}

</style>
